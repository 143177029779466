@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Hatton";
  src: url("./fonts/otf/PPHatton-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Hatton";
  src: url("./fonts/otf/PPHatton-BoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Hatton";
  src: url("./fonts/otf/PPHatton-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Hatton";
  src: url("./fonts/otf/PPHatton-MediumItalic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Hatton";
  src: url("./fonts/otf/PPHatton-Ultralight.otf") format("opentype");
  font-weight: light;
  font-style: normal;
}
@font-face {
  font-family: "Hatton";
  src: url("./fonts/otf/PPHatton-UltralightItalic.otf") format("opentype");
  font-weight: light;
  font-style: italic;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
